var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-main',{staticClass:"pa-0"},[_c('v-form',{ref:"formContactFooter",staticClass:"white--text",on:{"submit":function($event){$event.preventDefault();return _vm.sendEmailContact()}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"font-weight-regular",class:{
            border_white: !_vm.$store.getters['brand/getFooterTextColor'],
          },attrs:{"solo":"","label":_vm.$t('name'),"rules":_vm.required,"color":_vm.$store.getters['brand/getFooterTextColor']
              ? _vm.$store.getters['brand/getFooterTextColor']
              : '#000',"required":""},model:{value:(_vm.contact.name),callback:function ($$v) {_vm.$set(_vm.contact, "name", $$v)},expression:"contact.name"}})],1),_vm._v(" "),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"font-weight-regular",class:{
            border_white: !_vm.$store.getters['brand/getFooterTextColor'],
          },attrs:{"solo":"","label":_vm.$t('email'),"rules":_vm.rulesEmail,"color":_vm.$store.getters['brand/getFooterTextColor']
              ? _vm.$store.getters['brand/getFooterTextColor']
              : '#000',"required":""},model:{value:(_vm.contact.email),callback:function ($$v) {_vm.$set(_vm.contact, "email", $$v)},expression:"contact.email"}})],1),_vm._v(" "),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-textarea',{staticClass:"font-weight-regular",class:{
            border_white: !_vm.$store.getters['brand/getFooterTextColor'],
          },attrs:{"solo":"","rows":"3","label":_vm.$t('message'),"rules":_vm.required,"color":_vm.$store.getters['brand/getFooterTextColor']
              ? _vm.$store.getters['brand/getFooterTextColor']
              : '#000',"required":""},model:{value:(_vm.contact.message),callback:function ($$v) {_vm.$set(_vm.contact, "message", $$v)},expression:"contact.message"}})],1),_vm._v(" "),_c('v-col',{staticClass:"py-2",attrs:{"cols":"12"}},[_c('div',{attrs:{"id":"recaptcha-contact-footer"}})]),_vm._v(" "),_c('v-col',{staticClass:"py-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"float-right"},[_c('v-btn',{staticClass:"mt-0 mr-0 send-button font-weight-medium",attrs:{"dark":"","outlined":!_vm.valid || !_vm.reCAPTCHA || _vm.loading,"disabled":!_vm.valid || !_vm.reCAPTCHA || _vm.loading,"color":_vm.$store.getters['brand/getFooterButtonColor']
                ? _vm.$store.getters['brand/getFooterButtonColor']
                : '#0088CC',"type":"submit"}},[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):[_vm._v(_vm._s(_vm.$t('send')))]],2)],1)])],1)],1),_vm._v(" "),_c('v-snackbar',{attrs:{"top":true,"right":true,"timeout":_vm.snackbar.timeout,"color":_vm.snackbar.color,"multi-line":_vm.snackbar.mode === 'multi-line',"vertical":_vm.snackbar.mode === 'vertical'},model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v("\n    "+_vm._s(_vm.snackbar.text)+"\n    "),_c('v-btn',{staticClass:"float-right",attrs:{"icon":"","justify":"center","align":"center"},nativeOn:{"click":function($event){_vm.snackbar.show = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }